<template>
  <div class="card mb-4">
    <div class="card-header d-flex justify-content-between align-items-center">
      <h6
        class="mb-0"
        v-text="isian.status_relasi ? isian.status_relasi : 'Tambah Keluarga'"
      ></h6>
      <div v-if="!isian.id">
        <button
          @click="isian.id = isian.relasi_keluarga_id"
          class="btn btn-sm btn-primary"
        >
          <fa-icon :icon="['fa', 'edit']" class="mr-2" />Ubah
        </button>
      </div>
      <div v-if="isian.id">
        <button @click="action" class="btn btn-sm btn-success mr-2">
          <fa-icon :icon="['fa', 'save']" class="mr-2" />Simpan
        </button>
        <button
          @click="del"
          v-if="!(isian.id === true)"
          class="btn btn-sm btn-danger mr-2"
        >
          <fa-icon :icon="['fa', 'times']" class="mr-2" />Hapus
        </button>
        <button
          @click="
            isian.id = false;
            $emit('refreshBio', true);
          "
          class="btn btn-sm btn-secondary"
        >
          Batal
        </button>
      </div>
    </div>
    <div class="card-body">
      <form @submit.prevent>
        <div>
          <div class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-4 pt-0">
                Kewarganegaraan
              </legend>
              <div class="col-sm-8">
                <div class="form-check form-check-inline">
                  <input
                    name="warganegara"
                    :disabled="!isian.id"
                    v-validate="'required'"
                    v-model="isian.warganegara"
                    class="form-check-input"
                    type="radio"
                    id="wni"
                    value="WNI"
                  />
                  <label class="form-check-label" for="wni">WNI</label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    name="warganegara"
                    :disabled="!isian.id"
                    v-model="isian.warganegara"
                    class="form-check-input"
                    type="radio"
                    id="wna"
                    value="WNA"
                  />
                  <label class="form-check-label" for="wna">WNA</label>
                </div>
                <div
                  v-show="errors.has('warganegara')"
                  class="invalid-feedback"
                  v-text="errors.first('warganegara')"
                ></div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="no_passport" class="col-sm-4 col-form-label"
              >Nomor Passport</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <input
                    :disabled="isian.warganegara == 'WNI' || !isian.id"
                    name="No Passport"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('No Passport') }"
                    v-model="isian.no_passport"
                    type="text"
                    class="form-control form-control-sm"
                    id="no_passport"
                    placeholder="Nomor Passport"
                    autocomplete=""
                  />
                  <div
                    v-show="errors.has('No Passport')"
                    class="invalid-feedback"
                    v-text="errors.first('No Passport')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="nokk" class="col-sm-4 col-form-label">Nomor KK</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <input
                    :disabled="isian.warganegara == 'WNA' || !isian.id"
                    name="No.KK"
                    v-validate="'required|numeric|digits:16'"
                    :class="{ 'is-invalid': errors.has('No.KK') }"
                    v-model="isian.nokk"
                    type="text"
                    class="form-control form-control-sm"
                    id="nokk"
                    placeholder="Nomor Kartu Keluarga"
                    autocomplete=""
                  />
                  <div
                    v-show="errors.has('No.KK')"
                    class="invalid-feedback"
                    v-text="errors.first('No.KK')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="nik" class="col-sm-4 col-form-label">NIK</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-6">
                  <input
                    :disabled="isian.warganegara == 'WNA' || !isian.id"
                    name="NIK"
                    v-validate="'required|numeric|digits:16'"
                    :class="{ 'is-invalid': errors.has('NIK') }"
                    v-model="isian.nik"
                    type="text"
                    class="form-control form-control-sm"
                    id="nik"
                    placeholder="Nomor Induk Kependudukan"
                    autocomplete=""
                  />
                  <div
                    v-show="errors.has('NIK')"
                    class="invalid-feedback"
                    v-text="errors.first('NIK')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="nama_lengkap" class="col-sm-4 col-form-label"
              >Nama Lengkap</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-8">
                  <input
                    name="nama"
                    :disabled="!isian.id"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('nama') }"
                    v-model="isian.nama_lengkap"
                    autocomplete="name"
                    type="text"
                    class="form-control form-control-sm"
                    id="nama_lengkap"
                    placeholder="Nama Lengkap"
                  />
                  <div
                    v-show="errors.has('nama')"
                    class="invalid-feedback"
                    v-text="errors.first('nama')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <div class="row">
              <legend class="col-form-label col-sm-4 pt-0">
                Jenis Kelamin
              </legend>
              <div class="col-sm-8">
                <div class="form-check form-check-inline">
                  <input
                    name="jenis kelamin"
                    :disabled="
                      (isian.uuid && isian.uuid !== true) ||
                      !(isian.id === true)
                    "
                    v-validate="'required|included:L,P'"
                    v-model="isian.jenis_kelamin"
                    class="form-check-input"
                    type="radio"
                    id="perempuan"
                    value="P"
                    @click="handleStatusRelasi"
                  />
                  <label class="form-check-label" for="perempuan">
                    Perempuan
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    name="jenis kelamin"
                    :disabled="
                      (isian.uuid && isian.uuid !== true) ||
                      !(isian.id === true)
                    "
                    v-model="isian.jenis_kelamin"
                    class="form-check-input"
                    type="radio"
                    id="laki-laki"
                    value="L"
                    @click="handleStatusRelasi"
                  />
                  <label jenis class="form-check-label" for="laki-laki">
                    Laki-Laki
                  </label>
                </div>
                <div
                  v-show="errors.has('jenis kelamin')"
                  class="invalid-feedback"
                  v-text="errors.first('jenis kelamin')"
                ></div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="tempat_lahir" class="col-sm-4 col-form-label"
              >Tempat Lahir</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <input
                    name="tempat lahir"
                    :disabled="!isian.id"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('tempat lahir') }"
                    v-model="isian.tempat_lahir"
                    type="text"
                    class="form-control form-control-sm"
                    id="tempat_lahir"
                    placeholder="Tempat Lahir"
                  />
                  <div
                    v-show="errors.has('tempat lahir')"
                    class="invalid-feedback"
                    v-text="errors.first('tempat lahir')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="tanggal_lahir" class="col-sm-4 col-form-label"
              >Tanggal Lahir</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <tanggal
                    :readonly="!isian.id"
                    name="tanggal lahir"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('tanggal lahir') }"
                    v-model="isian.tanggal_lahir"
                    type="date"
                    class="form-control form-control-sm"
                    id="tanggal_lahir"
                  />
                  <div
                    v-if="errors.has('tanggal lahir')"
                    class="invalid-feedback"
                    v-text="errors.first('tanggal lahir')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="anak_ke" class="col-sm-4 col-form-label">Anak Ke</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-2">
                  <label class="sr-only" for="anak_ke">Anak ke</label>
                  <input
                    :disabled="!isian.id"
                    name="anak ke"
                    v-validate="'required|numeric'"
                    :class="{ 'is-invalid': errors.has('anak ke') }"
                    v-model="isian.anak_ke"
                    type="number"
                    class="form-control form-control-sm mb-2"
                    id="anak_ke"
                    min="0"
                  />
                  <div
                    v-show="errors.has('anak ke')"
                    class="invalid-feedback"
                    v-text="errors.first('anak ke')"
                  ></div>
                </div>
                <div class="col-auto">
                  <div class="mb-2">
                    <label class="form-check-label" for="jum_saudara">
                      Dari
                    </label>
                  </div>
                </div>
                <div class="col-md-2">
                  <label class="sr-only" for="jum_saudara"
                    >Jumlah Saudara</label
                  >
                  <input
                    :disabled="!isian.id"
                    name="jumlah saudara"
                    v-validate="'required|numeric'"
                    :class="{ 'is-invalid': errors.has('jumlah saudara') }"
                    v-model="isian.jum_saudara"
                    type="number"
                    class="form-control form-control-sm mb-2"
                    id="jum_saudara"
                    min="0"
                  />
                  <div
                    v-show="errors.has('jumlah saudara')"
                    class="invalid-feedback"
                    v-text="errors.first('jumlah saudara')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <!-- <div class="form-group row">
            <label for="tinggal_bersama" class="col-sm-4 col-form-label">Tinggal Bersama</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-4">
                  <input v-model="isian.tinggal_bersama" type="text" class="form-control form-control-sm" id="tinggal_bersama" placeholder="Tinggal Bersama">
                </div>
              </div>
            </div>
          </div> -->

          <div class="form-group row">
            <label for="pendidikan_terakhir" class="col-sm-4 col-form-label"
              >Pendidikan Terakhir</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <input
                    :disabled="!isian.id"
                    v-model="isian.pendidikan_terakhir"
                    type="text"
                    class="form-control form-control-sm"
                    id="pendidikan_terakhir"
                    placeholder="Nama Pendidikan/Sekolah Terakhir"
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- <div class="form-group row">
            <label for="email" class="col-sm-4 col-form-label">E-Mail</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <input name="email" v-validate="'email'" :class="{ 'is-invalid': errors.has('email') }" v-model="isian.email" type="text" class="form-control form-control-sm" id="email" placeholder="Email">
                  <div v-show="errors.has('email')" class="invalid-feedback" v-text="errors.first('email')"></div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="form-group row">
            <label for="phone1" class="col-sm-4 col-form-label"
              >No. Telepon 1</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <input
                    :disabled="!isian.id"
                    name="phone1"
                    v-validate="isRequiredForOrtu ? 'required' : ''"
                    :class="{ 'is-invalid': errors.has('phone1') }"
                    v-model="isian.phone1"
                    type="text"
                    class="form-control form-control-sm"
                    id="phone1"
                    placeholder="+62xxxxxxxxxx"
                  />
                  <div
                    v-show="errors.has('phone1')"
                    class="invalid-feedback"
                    v-text="errors.first('phone1')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="phone2" class="col-sm-4 col-form-label"
              >No. Telepon 2</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <input
                    :disabled="!isian.id"
                    v-model="isian.phone2"
                    type="text"
                    class="form-control form-control-sm"
                    id="phone2"
                    placeholder="+62xxxxxxxxxx"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="pekerjaan" class="col-sm-4 col-form-label"
              >Pekerjaan</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <select
                    :disabled="!isian.id"
                    name="pekerjaan"
                    v-validate="
                      (isRequiredForOrtu ? 'required|' : '') + 'numeric'
                    "
                    :class="{ 'is-invalid': errors.has('pekerjaan') }"
                    v-model="isian.id_pekerjaan"
                    id="pekerjaan"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Pekerjaan</option>
                    <option
                      v-for="(field, id) in pekerjaan"
                      :key="id"
                      :value="field.id"
                      v-text="field.nama"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('pekerjaan')"
                    class="invalid-feedback"
                    v-text="errors.first('pekerjaan')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="penghasilan" class="col-sm-4 col-form-label"
              >Penghasilan</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-5">
                  <select
                    :disabled="!isian.id"
                    name="penghasilan"
                    v-validate="
                      (isRequiredForOrtu ? 'required|' : '') + 'numeric'
                    "
                    :class="{ 'is-invalid': errors.has('penghasilan') }"
                    v-model="isian.id_penghasilan"
                    id="penghasilan"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Penghasilan</option>
                    <option
                      v-for="(field, id) in penghasilan"
                      :key="id"
                      :value="field.id"
                      v-text="field.keterangan"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('penghasilan')"
                    class="invalid-feedback"
                    v-text="errors.first('penghasilan')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-group row">
            <label for="negara" class="col-sm-4 col-form-label">Negara</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <select
                    :disabled="!isian.id"
                    name="negara"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('negara') }"
                    v-model="isian.id_negara"
                    @change="handleNegara"
                    id="negara"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Negara</option>
                    <option
                      v-for="(negara, id) in alamat.negara"
                      :key="id"
                      :value="negara.id"
                      v-text="negara.nama"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('negara')"
                    class="invalid-feedback"
                    v-text="errors.first('negara')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="provinsi" class="col-sm-4 col-form-label"
              >Provinsi</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <select
                    name="provinsi"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('provinsi') }"
                    v-model="isian.id_provinsi"
                    :disabled="
                      (isian.id_negara == null ? true : false) || !isian.id
                    "
                    @change="handleProvinsi"
                    id="provinsi"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Provinsi</option>
                    <option
                      v-for="(provinsi, id) in alamat.provinsi"
                      :key="id"
                      v-text="provinsi.nama"
                      :value="provinsi.id"
                    ></option>
                  </select>
                </div>
                <div
                  v-show="errors.has('provinsi')"
                  class="invalid-feedback"
                  v-text="errors.first('provinsi')"
                ></div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="kabupaten" class="col-sm-4 col-form-label"
              >Kabupaten</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <select
                    name="kabupaten"
                    v-validate="'required'"
                    :class="{ 'is-invalid': errors.has('kabupaten') }"
                    v-model="isian.id_kabupaten"
                    :disabled="
                      (isian.id_provinsi == null ? true : false) || !isian.id
                    "
                    @change="handleKabupaten"
                    id="kabupaten"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Kabupaten</option>
                    <option
                      v-for="(kabupaten, id) in alamat.kabupaten"
                      :key="id"
                      v-text="kabupaten.nama"
                      :value="kabupaten.id"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('kabupaten')"
                    class="invalid-feedback"
                    v-text="errors.first('kabupaten')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="kecamatan" class="col-sm-4 col-form-label"
              >Kecamatan</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <select
                    name="kecamatan"
                    v-validate="''"
                    :class="{ 'is-invalid': errors.has('kecamatan') }"
                    v-model="isian.id_kecamatan"
                    :disabled="
                      (isian.id_kabupaten == null ? true : false) || !isian.id
                    "
                    id="kecamatan"
                    class="form-control form-control-sm"
                  >
                    <option :value="null">Pilih Kecamatan</option>
                    <option
                      v-for="(kecamatan, id) in alamat.kecamatan"
                      :key="id"
                      :value="kecamatan.id"
                      v-text="kecamatan.nama"
                    ></option>
                  </select>
                  <div
                    v-show="errors.has('kecamatan')"
                    class="invalid-feedback"
                    v-text="errors.first('kecamatan')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <hr />

          <div class="form-group row">
            <label for="jalan" class="col-sm-4 col-form-label">Jalan</label>
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-md-8">
                  <input
                    :disabled="!isian.id"
                    name="jalan"
                     maxlength="99"
                    v-validate="'required|min:6'"
                    :class="{ 'is-invalid': errors.has('jalan') }"
                    v-model="isian.jalan"
                    type="text"
                    class="form-control form-control-sm"
                    id="jalan"
                    placeholder="Jalan atau Detail Alamat"
                  />
                  <div
                    v-show="errors.has('jalan')"
                    class="invalid-feedback"
                    v-text="errors.first('jalan')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group row">
            <label for="kodepos" class="col-sm-4 col-form-label"
              >Kode POS</label
            >
            <div class="col-sm-8">
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <input
                    :disabled="!isian.id"
                    name="kode pos"
                    v-validate="'required|min:5'"
                    :class="{ 'is-invalid': errors.has('kode pos') }"
                    v-model="isian.kodepos"
                    autocomplete="postal-code"
                    type="text"
                    class="form-control form-control-sm"
                    id="kodepos"
                    placeholder="Kode Pos"
                  />
                  <div
                    v-show="errors.has('kode pos')"
                    class="invalid-feedback"
                    v-text="errors.first('kode pos')"
                  ></div>
                </div>
              </div>
            </div>
          </div>

          <hr />
        </div>

        <div class="form-group row">
          <label for="status" class="col-sm-4 col-form-label"
            >Status Orangtua / Wali</label
          >
          <div class="col-sm-8">
            <div class="form-row align-items-center">
              <div class="col-md-5">
                <select
                  name="Status"
                  v-validate="'required'"
                  :disabled="!isian.jenis_kelamin || !isian.id"
                  :class="{ 'is-invalid': errors.has('Status') }"
                  v-model="isian.kode_relasi"
                  id="status"
                  class="form-control form-control-sm"
                >
                  <option :value="null">Pilih Status</option>
                  <option
                    v-for="(status, id) in opsi_relasi"
                    :key="id"
                    :value="status.kode_relasi"
                    v-text="status.status_person_lawan"
                  ></option>
                </select>
                <div
                  v-show="errors.has('Status')"
                  class="invalid-feedback"
                  v-text="errors.first('Status')"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="row">
            <legend class="col-form-label col-sm-4 pt-0">Sebagai Wali</legend>
            <div class="col-sm-8">
              <div class="form-check form-check-inline">
                <input
                  :disabled="!isian.id"
                  name="Sebagai Wali"
                  v-validate="'required'"
                  v-model="isian.sebagai_wali"
                  class="form-check-input"
                  type="radio"
                  id="sebagai-wali-y"
                  value="Y"
                />
                <label class="form-check-label" for="sebagai-wali-y">Ya</label>
              </div>
              <div class="form-check form-check-inline">
                <input
                  :disabled="!isian.id"
                  name="Sebagai Wali"
                  v-model="isian.sebagai_wali"
                  class="form-check-input"
                  type="radio"
                  id="sebagai-wali-t"
                  value="T"
                />
                <label class="form-check-label" for="sebagai-wali-t"
                  >Tidak</label
                >
              </div>
              <div
                v-show="errors.has('Sebagai Wali')"
                class="invalid-feedback"
                v-text="errors.first('Sebagai Wali')"
              ></div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="card-footer d-flex justify-content-between align-items-center">
      <h6
        class="mb-0"
        v-text="isian.status_relasi ? isian.status_relasi : 'Tambah Keluarga'"
      ></h6>
      <div v-if="!isian.id">
        <button
          @click="isian.id = isian.relasi_keluarga_id"
          class="btn btn-sm btn-primary"
        >
          <fa-icon :icon="['fa', 'edit']" class="mr-2" />Ubah
        </button>
      </div>
      <div v-if="isian.id">
        <button @click="action" class="btn btn-sm btn-success mr-2">
          <fa-icon :icon="['fa', 'save']" class="mr-2" />Simpan
        </button>
        <button
          @click="del"
          v-if="!(isian.id === true)"
          class="btn btn-sm btn-danger mr-2"
        >
          <fa-icon :icon="['fa', 'times']" class="mr-2" />Hapus
        </button>
        <button
          @click="
            isian.id = false;
            $emit('refreshBio', true);
          "
          class="btn btn-sm btn-secondary"
        >
          Batal
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Tanggal from "../../components/Tanggal";

export default {
  components: { Tanggal },
  props: ["person", "biodatas"],
  data() {
    return {
      isian: {},
      opsi_relasi: [],
      pekerjaan: [],
      penghasilan: [],
      alamat: {
        negara: [],
        provinsi: [],
        kabupaten: [],
        kecamatan: [],
      },
    };
  },
  mounted() {
    this.init();
  },
  watch: {
    person: {
      handler() {
        this.init();
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.isian = Object.assign(
        {
          id: false, // default false & status tidak dalam editmode
          kode_relasi: this.person.relasi_keluarga.kode_relasi,
          sebagai_wali: this.person.relasi_keluarga.sebagai_wali,
          status_relasi: this.person.relasi_keluarga.status_relasi,
          relasi_keluarga_id: this.person.relasi_keluarga.id,
        },
        this.person
      );

      this.getNegara();
      this.getPenghasilan();
      this.getPekerjaan();
      if (
        (this.isian.uuid_person_lawan || !this.isian.uuid) &&
        (!this.isian.uuid_person_lawan || this.isian.uuid)
      )
        return;
      // tambah tidak di jalankan edit dijalankan
      this.$psb.api
        .getFullAlamat({
          id_kecamatan: this.isian.id_kecamatan,
          id_kabupaten: this.isian.id_kabupaten,
        })
        .then((data) => {
          this.isian.id_negara = data.negara.id;
          this.isian.id_provinsi = data.prov.id;
          this.isian.id_kabupaten = data.kab.id;
          this.isian.id_kecamatan = data.kec.id;
          this.alamat.provinsi = data.negara.list_provinsi;
          this.alamat.kabupaten = data.prov.list_kabupaten;
          this.alamat.kecamatan = data.kab.list_kecamatan;
        })
        .catch(() => {});
      this.handleStatusRelasi(false);
    },
    action() {
      let doit = () => {
        // if(this.isian.isian_datadiri) {
        var data = {
          warganegara: this.isian.warganegara,
          no_passport: this.isian.no_passport,
          nik: this.isian.nik,
          nokk: this.isian.nokk,
          nama_lengkap: this.isian.nama_lengkap,
          tempat_lahir: this.isian.tempat_lahir,
          tanggal_lahir: this.isian.tanggal_lahir,
          jenis_kelamin: this.isian.jenis_kelamin,
          anak_ke: parseInt(this.isian.anak_ke),
          jum_saudara: parseInt(this.isian.jum_saudara),
          // tinggal_bersama: this.isian.tinggal_bersama,
          pendidikan_terakhir: this.isian.pendidikan_terakhir,

          phone1: this.isian.phone1,
          phone2: this.isian.phone2,
          id_pekerjaan: this.isian.id_pekerjaan,
          id_penghasilan: this.isian.id_penghasilan,

          id_kecamatan: this.isian.id_kecamatan,
          id_kabupaten: this.isian.id_kabupaten,
          jalan: this.isian.jalan,
          kodepos: this.isian.kodepos,

          relasi_keluarga: {
            kode_relasi: this.isian.kode_relasi,
            sebagai_wali: this.isian.sebagai_wali,
          },
        };

        this.$store.commit("setLoading", true);
        this.$axios({
          method: this.isian.id === true ? "post" : "put",
          url:
            "/pendaftar/" +
            this.biodatas.uuid +
            "/keluarga" +
            (this.isian.id === true ? "" : "/" + this.isian.id),
          data,
        })
          .then((res) => {
            this.$store.commit("setLoading", false);
            this.$root.$emit("setNotif", {
              title: res.data.status,
              message: res.data.deskripsi,
              type: "success",
            });
            this.isian.id = false;
            this.$emit("refreshBio", true);
          })
          .catch(() => {})
          .finally(() => {
            this.$store.commit("setLoading", false);
          });
      };

      this.$validator.validateAll().then((result) => {
        if (result) doit();
      });
    },
    del() {
      this.$root.$emit("setNotif", {
        title: "Yakin Hapus!",
        type: "warning",
        customConfirmBtnText: "Hapus",
        useConfirmBtn: true,
        customConfirmBtnClass: "btn btn-danger mr-2",
        customCloseBtnClass: "btn btn-primary",
        // onClose: () => {},
        onConfirm: () => {
          if (!this.isian.id || this.isian.id === true)
            throw Error(`property 'this.isian.relasi_keluarga.id' kosong!`);
          this.$store.commit("setLoading", true);
          this.$axios
            .delete(
              "/pendaftar/" + this.biodatas.uuid + "/keluarga/" + this.isian.id
            )
            .then((res) => {
              this.$store.commit("setLoading", false);
              this.$psb.$emit("setNotif", {
                title: res.data.status,
                message: res.data.deskripsi,
                type: "success",
              });
              this.isian.id = false;
              this.$emit("refreshBio", true);
            })
            .catch(() => {});
        },
      });
    },

    getPekerjaan() {
      this.$axios
        .get("/setting/pekerjaan")
        .then((res) => {
          this.pekerjaan = res.data;
        })
        .catch(() => {});
    },
    getPenghasilan() {
      this.$axios
        .get("/setting/penghasilan")
        .then((res) => {
          this.penghasilan = res.data;
        })
        .catch(() => {});
    },
    getNegara() {
      this.$axios
        .get("/setting/negara")
        .then((res) => {
          this.alamat.negara = res.data;
        })
        .catch(() => {});
    },
    handleStatusRelasi(force = true) {
      setTimeout(() => {
        if (!this.biodatas.jenis_kelamin) return;
        if (force) {
          this.isian.kode_relasi = null;
          this.$validator.reset();
        }
        this.$axios
          .get(
            `/setting/relasikeluarga/opsi/${this.biodatas.jenis_kelamin}/${this.isian.jenis_kelamin}`
          )
          .then((res) => {
            this.opsi_relasi = res.data;
          })
          .catch(() => {});
      }, 0);
    },
    handleNegara() {
      this.$psb.api.Select.handleNegara(this.isian.id_negara, (data) => {
        this.isian.id_provinsi = data.provinsi;
        this.isian.id_kabupaten = data.kabupaten;
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.provinsi = data.list_provinsi;
      });
    },
    handleProvinsi() {
      this.$psb.api.Select.handleProvinsi(this.isian.id_provinsi, (data) => {
        this.isian.id_kabupaten = data.kabupaten;
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.kabupaten = data.list_kabupaten;
      });
    },
    handleKabupaten() {
      this.$psb.api.Select.handleKabupaten(this.isian.id_kabupaten, (data) => {
        this.isian.id_kecamatan = data.kecamatan;
        this.alamat.kecamatan = data.list_kecamatan;
      });
    },
  },
  computed: {
    isRequiredForOrtu() {
      return (
        !!this.isian.kode_relasi &&
        (this.isian.kode_relasi == "9Y" || this.isian.kode_relasi == "12Y")
      );
    },
  },
};
</script>

<style scoped></style>
